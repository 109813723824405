

.alert a {
  font-weight: bold;
  text-decoration: none;
  color: #316BBE;
}

.alert2 a{
  font-weight: bold;
  text-decoration: none;
  color: #3bb6a7;
}

.content_list
{
   overflow-wrap: break-word;
}


.content_list li {
  margin-left:-35px;
  padding-left: 35px;
  margin-bottom: 10px;
  min-height: 28px;
  display: flex;
 
  align-items:center;
  background: url('/assets/leaf.png') no-repeat left ;
}

.content_list a {
  text-decoration: none;
  color: #316BBE;


}


.content_list a:after {
  content: "";
  font-family: "Optum-Micro-interaction-16";
  display: inline-block;
  font-size: 0.75em;
  font-weight: bold;
  padding-left: 2px;
  vertical-align: middle;
}

.content_list a:hover {
  text-decoration: none;
  color: #00396C;

}

.l2pages a {
  text-decoration: none;
  color: #316BBE;
}

.l2pages a:hover {
  text-decoration: none;
  color: #00396C;

}


.content_list ul {
  list-style-type: none;
}



 
@font-face {
  font-family: 'Brush Script MT';
  font-style: italic;
  src: url("./custom/fonts/Brush Script.ttf") format('truetype');
  src: url("./custom/fonts/Brush-Script.woff") format('woff');
}




 
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("./custom/fonts/MaterialIcons-Regular.eot");
  /* IE9 Compat Modes */
  src: url("./custom/fonts/MaterialIcons-Regular.eot?#iefix") format("embedded-opentype"), url("./custom/fonts/MaterialIcons-Regular.woff") format("woff"), url("./custom/fonts/MaterialIcons-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Optum Icon Font';
  font-style: normal;
  font-weight: 400;
  src: url("./custom/fonts/Optum-Icon-Font.eot");
  /* IE9 Compat Modes */
  src: url("./custom/fonts/Optum-Icon-Font.eot?#iefix") format("embedded-opentype"), url("./custom/fonts/Optum-Icon-Font.woff") format("woff"), url("./custom/fonts/Optum-Icon-Font.ttf") format("truetype"); }

@font-face {
  font-family: 'Optum-Micro-interaction-24';
  font-style: normal;
  font-weight: 400;
  src: url("./custom/fonts/Optum-Micro-interaction-24.eot?ksgutp");
  src: url("./custom/fonts/Optum-Micro-interaction-24.eot?ksgutp#iefix") format("embedded-opentype"), url("./custom/fonts/Optum-Micro-interaction-24.woff2?ksgutp") format("woff2"), url("./custom/fonts/Optum-Micro-interaction-24.ttf?ksgutp") format("truetype"), url("./custom/fonts/Optum-Micro-interaction-24.woff?ksgutp") format("woff"); }

@font-face {
  font-family: 'Optum-Micro-interaction-16';
  font-style: normal;
  font-weight: 400;
  src: url("./custom/fonts/Optum-Micro-interaction-16.eot?d5jy79");
  src: url("./custom/fonts/Optum-Micro-interaction-16.eot?d5jy79#iefix") format("embedded-opentype"), url("./custom/fonts/Optum-Micro-interaction-16.woff2?d5jy79") format("woff2"), url("./custom/fonts/Optum-Micro-interaction-16.ttf?d5jy79") format("truetype"), url("./custom/fonts/Optum-Micro-interaction-16.woff?d5jy79") format("woff"); }

